 import React, { useState } from 'react';
 import "./navbar.css";

 function Navbar() {

   const scrollToSection = (sectionId) => {
     const section = document.getElementById(sectionId);
    if (section) {
       window.scrollTo({
         behavior: "smooth",
         top: section.offsetTop
       });
    }
  };

const [menuActive, setMenuActive] = useState(false);

const toggleMenu = () => {
    setMenuActive(!menuActive);
};

   return (
     <>
       <div className='navbar-container'>
       <img
          src={process.env.PUBLIC_URL + `/first.png`}
          className="first-img"
          alt=""
        ></img>
         <ul id="menu" className={menuActive ? "active" : ""}>
        
           <li><a href="#home" onClick={() => scrollToSection("home")}>परिषदेचे कार्य</a></li>
           <li><a href="#about" onClick={() => scrollToSection("about")}>उपक्रम व उद्दिष्ट्ये</a></li>
           <li><a href="#numbers" onClick={() => scrollToSection("numbers")}>शिबीर संख्या</a></li>
           <li><a href="#contact" onClick={() => scrollToSection("contact")}>संपर्क</a></li>
         </ul>
<div className="hamburger-menu" onClick={toggleMenu}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M4 18h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z"/></svg>
                </div>
           
       </div>
    </>
  );
 }

 export default Navbar;

