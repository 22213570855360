import React from 'react';
import { BrowserRouter as Router,Route,Routes } from 'react-router-dom';
import Navbar from './pages/navbar';
import Home from './pages/home';
function App() {
  return (
    <Router>
    <Navbar />
    <Routes>
      <Route path="/" element={<Home />} />
      {/* Define routes for other sections */}
    </Routes>
  </Router>
  );
}

export default App;
