import React from "react";
import "./home.css";
import { Link } from "react-router-dom";
function Home() {
  return (
    <>
      <div className="first-part">
        {/* <h2 className="first-text">अखिल भारतीय पंचकृष्ण प्रबोधन परिषद</h2>
        <h4 className="text">श्रीक्षेत्र जाळीचादेव (रजि.)</h4>
        <div className="box">
          <h5 className="text2">* प्रधान कार्यालय *</h5>
        </div>
        <h2 className="text3">श्रीगोपाल आश्रम</h2>
        <h4 className="text4">अजिंठा रोड, बुलडाणा</h4> */}
        <img
          src={process.env.PUBLIC_URL + `/img.jpeg`}
          className="img"
          alt=""
        ></img>
      </div>
      <div className="second-part">
        <ul className="part">
          <li>पंचतत्व</li>
          <li>सत्य</li>
          <li>अहिंसा</li>
          <li>समानता</li>
          <li>मानव प्रबोधन</li>
          <li>व्यसनमुक्ती</li>
        </ul>
      </div>

      <section id="home">
        <h2 className="home-text">परिषदेचे कार्य</h2>
        <h4 className="home-text2">
        मुलामुलींना आपल्या कर्तव्याची जाणीव करून देण्यासाठी ७ दिवशीय निवासी विनामूल्य बालसंस्कार शिबीरांचे आयोजन. पुरुष व महिलांना आपल्या कर्तव्याची तथा अधिकाराची जाणीव करून आपल्या बालकांना सुसंस्कार देण्यासाठी ७ दिवशीय निवासी आत्मविकास प्रबोधन शिबिरांचे विनामूल्य आयोजन.
        </h4>
       
        <ul className="list">
          <li>* साप्ताहिक सत्संग</li>
          <li>* तालुका प्रशिक्षण मेळावे एक दिवसीय कार्यक्रम संस्कार शिबिरे</li>
          <li>* वाचनालय</li>
          <li>* अवतार दिन महोत्सव राज्यस्तरीय गीता ज्ञान स्पर्धा परीक्षा</li>
        </ul>
      
      </section>
      <div className="about-text">
        अखिल भारतीय पंचकृष्ण प्रबोधन परिषद-श्रीक्षेत्र जाळीचादेव
        </div>
        <p className="about-text2">सर्वज्ञ श्रीचक्रधरस्वामींच्या स्त्रीशूद्र समतेच्या अंधश्रद्धा निर्मूलनाच्या विचारांचा प्रचार प्रसार शहरापासून खेड्यापर्यंत, विद्वानापासून अशिक्षितांपर्यंत, श्रीमंतापासून ग्रामीण झोपपट्टीतील बंधु-भगीनींपर्यंत पोहचविण्यासाठी संस्थेची स्थापना :</p>
        <p className="about-text3">* संस्थेने 32 वर्षा पासून राबवलेल्या उपक्रमाचा आढावा *
</p>
      <section id="about">
        
      <div className="card">
  <div className="card-body">
    <h5 className="card-title">१) साप्ताहिक सत्संग :</h5>
    <p className="card-text">उद्दिष्ट्ये : दर आठवड्याला गावातील बंधू-भगीनी एकाच घरी येऊन २ तास पूजा, वंदन, चिंतन करावे, सहजीवन जगणे शिकावे, आपसातील गैरसमज दूर करणे, सज्जनता वाढविणे. महाराष्ट्रातील १७ जिल्ह्यातील ६३० गावातील साधारणतः दर आठवड्याला १८९०० भक्तगण एकत्र येऊन कार्यक्रम करतात.</p>
   </div>
  </div>
  <div className="card">
  <div className="card-body">
    <h5 className="card-title">२) तालुका प्रशिक्षण मेळावे एक दिवसीय कार्यक्रम :</h5>
    <p className="card-text">प्रत्येक खेड्यातील सर्वांनी स. ९ ते सायं. ५ वा. पर्यंत हजर राहणे, सोबत दुपारी जेवणासाठी उपहारासाठी शिदोरी आणणे. ४ वाजेपर्यंत विविध कार्यक्रम करून आपल्या गावी जाणे. उद्दिष्ट्ये : परमेश्वरांनी सांगितलेला विचार समजावूण घेणे. दररोजच्या आचारणीय बाबींची माहिती करून घेणे. मेळाव्यांचा आढावा : १२ जिल्ह्यातील ५५ तालुक्यामधून ८२५ गावात कार्यक्रम होतात. अनेक मुले, मुली, महिला, पुरुषांनी लाभ घेतात.
</p>
   </div>
  </div>
  <div className="card">
  <div className="card-body">
    <h5 className="card-title">३) संस्कार शिबिरे महिला, पुरुष, मुले, मुली यांचे दरवर्षी ६ दिवसाचे वेगवेगळ्या तारखांना निवासी शिबिराचे विनामूल्य आयोजन.</h5>
    <p className="card-text">उद्दिष्ट्ये : एकत्र राहणे, सद्‌गुण वाढविणे, ईश्वरीय संदेशाच्या आचारणीय जीवनविकासाच्या सामाजिक व अध्यात्तमीक बार्बीची माहिती करून घेणे, त्याप्रमाणे वागणे. सन १९९३ ते २०24  पर्यंतच्या आयोजित केलेल्या 32 वर्षातील शिबिरांचा आढावा.
</p>
   </div>
  </div>
  <div className="card">
  <div className="card-body">
    <h5 className="card-title">४) वाचनालयः प्रारंभ १९९९</h5>
    <p className="card-text"> उद्दिष्ट्ये: <li>१. खेडेगावात एका कार्यकर्त्यांच्या घरी पुस्तके ठेवावीत, इतरांनी ती वाचनासाठी न्यावी व परत करावीत. वाचनाची सवय लागावी.</li>
    <li> २. कमीत कमी २५ पुस्तकापासून १५० पुस्तकापर्यंत असलेली वाचनालयांची संख्या ६५ गावे, लाभार्थी वाचक संख्या दरवर्षी १४००</li>
</p>
   </div>
  </div>
  <div className="card">
  <div className="card-body">
    <h5 className="card-title"> उपक्रम ५)
बुलडाणा जिल्हा सर्वज्ञ श्रीचक्रधरस्वामी अवतार दिन महोत्सव :</h5>
    <p className="card-text">
    बुलडाणा, तेल्हारा, मेहकर, खामगांव, जळगांव- जामोद, नांदुरा, परडा, मलकापूर, जाफ्राबाद, अकोला इ. ठिकाणी सर्वज्ञ श्रीचक्रधरस्वामी अवतारदिन महोत्सवाचे सामुहिक आयोजन करण्यात आले. विद्यार्थी, महिला पुरुषांच्या विविध स्पर्धा स्वामींच्या चरित्रावर भजन किर्तन, प्रवचन, शोभायात्रा आदी कार्यक्रमाचे त्यात आयोजन असते. जिल्ह्यातील ९०-९५ खेड्यातून सुमारे ९ हजारापेक्षा
अधिक भक्तांची उपस्थिती असते. सन १९९५ पासून आजपर्यंत २३ वर्षापासून हा महोत्सव सुरु आहे
</p>
   </div>
  </div>
  <div className="card">
  <div className="card-body">
    <h5 className="card-title"> ६) श्रीकृष्ण चक्रवर्ती अवतार दिन महोत्सव (कृष्णाष्टमी) आयोजक बाल किशोर विद्यार्थी</h5>
    <p className="card-text">उद्दिष्ट्ये :
<li>१. आपली मुले इतरांच्या होणाऱ्या कार्यक्रमात सहभाग देऊन कार्यक्रम करतात. आपली मुले आपल्याच कार्यक्रमात राहावीत त्यांनी स्वतः कार्यक्रमाचे आयोजन करावे व आनंद घ्यावा व संस्कार वाढवावेत.</li>
<li>२. प्रत्येक गावात कृष्णाष्टमीच्या ८ दिवसाच्या कार्यक्रमाचे आयोजन करणे.</li>
<li>३. गावात एका ठिकाणी श्रीकृष्ण मूर्तीची स्थापना करुन दररोज आरती करणे. श्रीकृष्णावर आधारित दररोज संध्याकाळी भजन, किर्तन, प्रवचन, कथाकथन, भजन-स्पर्धा, गीता श्लोक स्पर्धा अशा कार्यक्रमाचे आयोजन.</li>
<li>४. शेवटच्या दिवशी प्रत्येक घरच्या लहान मुलांना श्रीकृष्णासारखे सजवून सर्वांची वाद्य लावून मिरवणूक काढणे.</li>
<li>५. शेवटच्या दिवशी गोपाळकाला म्हणून अन्नदान करणे. ६. ४५ गावात अशा उपक्रमाचे आयोजन मुलेच करतात.</li>

</p>
   </div>
  </div>
  <div className="card">
  <div className="card-body">
    <h5 className="card-title"> उपक्रम ७)
पोष्टाद्वारे श्रीमद्भगवद्गीता ज्ञान परीक्षा:सन १९९३ पासून २००५ पर्यंत १२ वर्षाचा उपक्रम.</h5>
    <p className="card-text">
   उद्दिष्ट्ये : सर्वांनी गीतेचा अभ्यास करावा प्रत्येक अध्यायाची प्रश्न पत्रिका घरी पाठवून सोडवून मागितली जाते. तपासून त्यांचे गुण
व दुसऱ्या अध्यायाची प्रश्न पत्रिका दर महिन्याला पाठविली जाते. अशा प्रकारे १८ महिन्यात गीतेचा अभ्यास पूर्ण होतो.
<br/>
सहभाग :
<li>१) एकुण ९ जिल्ह्यातील </li> <li>२) २६३ गावातील महिला पुरुष </li> <li> ३) दरवर्षीची परीक्षार्थी संख्या ५२००</li>
<li>४) एकुण १२ वर्षातील अभ्यासक्रम पूर्ण करणाऱ्यांची लाभार्थी संख्या ६२४००</li>


</p>
   </div>
  </div>
  
  <div className="card">
  <div className="card-body">
    <h5 className="card-title">  ८) राज्यस्तरीय गीता ज्ञान स्पर्धा परीक्षा : प्रारंभ २००४ पासून</h5>
    <p className="card-text">उद्दिष्ट्ये :शाळांतील वर्ग ५ पासून पुढील कॉलेजपर्यंतच्या वर्गातील विद्यार्थी, गावातील प्रौढ महिला पुरुष व तरुणांनी गीता अभ्यासावी. स्थळ : शाळा, कॉलेज, समाज मंदिर इ. ठिकाणी दरवर्षी गोकुळ अष्टमी पूर्वीचा रविवार परीक्षा वेळ १ तास वस्तुनिष्ट प्रश्न, सहभागी २२ जिल्ह्यातील, सहभागी शाळा, सन २००७ पासून आज पर्यंतचा 14 वर्षाच्या उपक्रमातील सहभागी विद्यार्थी १ लाखापेक्षा अधिक आहेत.

</p>
   </div>
  </div>
      </section>
      <section id="numbers">
      <div className="number-container">शिबीर संख्या</div>
        <div className="table-container">
      <table>
  <thead>
    <tr>
      <th>आयोजिलेली शिबिरे</th>
      <th>शिबीर संख्या</th>
      <th>सहभागी प्रशिक्षणार्थी</th>
      <th>एकुण जिल्हे</th>
      <th>गावाची संख्या</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>मुलांसाठी</td>
      <td>१९५</td>
      <td>७२६५०</td>
      <td>२२</td>
      <td>३६०</td>
    </tr>
    <tr>
      <td>मुलींसाठी</td>
      <td>९२</td>
      <td>२४८३०</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>महिलांसाठी</td>
      <td>९२</td>
      <td>२७६१५</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>पुरुषांसाठी</td>
      <td>९२</td>
      <td>२८७४०</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>एकुण</td>
      <td>३८१</td>
      <td>१४३९३४</td>
      <td>२२</td>
      <td>३६०</td>
    </tr>
   
  </tbody>
</table>
<div className="center">
<button className="btn"><i className="fa fa-download"></i> For Download Our App Click Here</button>

<Link
              to="https://play.google.com/store/apps/details?id=com.shreematbhagwat.geeta"
              target="_blank" // Open link in a new tab
              rel="noopener noreferrer" // Required for security reasons
            >
<img className="bn46" src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png" alt=""/>
</Link>
</div>
</div>

      </section>
      <section id="contact">
      <div className="detail">
      Copywrite: अखिल भारतीय पंचकृष्ण प्रबोधन परिषद, बुलडाणा
. मो. +९१ ९८८१६७०२३६

      </div>
      </section>
    </>
  );
}

export default Home;
